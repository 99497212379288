import React from "react";
import "../styles/Home.css";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import CreateIcon from "@mui/icons-material/Create";
import DashboardIcon from "@mui/icons-material/Dashboard";
// import InsightsIcon from '@mui/icons-material/Insights';
import Search from "@mui/icons-material/Search";
import ArticleIcon from "@mui/icons-material/Article";
// import WidgetsIcon from '@mui/icons-material/Widgets';
import PieChartIcon from "@mui/icons-material/PieChart";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { motion } from "framer-motion";
import axios from 'axios';
import { proxy } from '../proxy';
import { useEffect, useState } from "react";
import { useSelector ,useDispatch } from 'react-redux';
import { setProfile } from "../features/profileSlice";
import GroupsIcon from "@mui/icons-material/Groups";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
function Home(props) {
  const history = useHistory();
  const { user } = useAuthenticator((context) => [context.user]);
  const dispatch = useDispatch();
  const profile= useSelector((state) => {
    return state.profile;
  });
  const [available,setAvailable]= useState(true);
  const able =user.attributes['custom:switch'];
  const [isLoading,setisLoading]=useState(true);
  let roles = [];
  if (
    user !== undefined &&
    user.signInUserSession.idToken.payload["cognito:groups"] !== undefined
  ) {
    roles = user.signInUserSession.idToken.payload["cognito:groups"];
  }
  const interviewType = user.attributes["custom:interviewType"];

  useEffect(() => {
    axios
.get(`${proxy}/api/role`, {
  withCredentials: true,
  headers: {
    Authorization: user.signInUserSession.idToken.jwtToken,
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
  },
})
.then((res) => {
  if(!able)
  {
    dispatch(setProfile(user.attributes.profile));
    localStorage.setItem('myString',user.attributes.profile);
  }
  setAvailable(true);
  setisLoading(false);
  console.log(user);
  console.log(res.data.result);
})
.catch((error) => {
  setAvailable(false);
  setisLoading(false);
  console.log(error);
});
}, []);

  return (
    <React.Fragment>
      {/* <div className='downtime-strip'><p><span>Hiring Probe Form Application</span>, will not be available from <span>Wed, Feb 15, 07:00 PM IST to Thurs, Feb 16, 09:00 AM IST</span> for System Upgrade.</p></div>
        <hr/> */}
      <div className="links-box">
        {roles.includes("Admin") && (
          <div className="links-role-div">
            <h2 style={{ marginLeft: "50px" }}>Admin</h2>
            <div
              style={{
                borderRadius: "20px",
                display: "flex",
                backgroundColor: "#E5E4E2",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "10px",
                padding: "40px",
              }}
            >
              {roles.includes("Admin") && (
                <Link to="/sections" style={{ textDecoration: "none" }}>
                  <motion.div
                    whileHover={{
                      scale: 1.2,
                    }}
                  >
                    <div className="link-box">
                      <div className="link-box-icon">
                        <DashboardIcon sx={{ width: "60px", height: "90px" }} />
                      </div>
                      <div className="link-box-title">
                        <p>Sections and Subsections</p>
                      </div>
                    </div>
                  </motion.div>
                </Link>
              )}
              {roles.includes("Admin") && (
                <Link to="/templates" style={{ textDecoration: "none" }}>
                  <motion.div
                    whileHover={{
                      scale: 1.2,
                    }}
                  >
                    <div className="link-box">
                      <div className="link-box-icon">
                        <ArticleIcon sx={{ width: "60px", height: "90px" }} />
                      </div>
                      <div className="link-box-title">
                        <p>Templates</p>
                      </div>
                    </div>
                  </motion.div>
                </Link>
              )}

              {roles.includes("Admin") && (
                <Link to="/roles" style={{ textDecoration: "none" }}>
                  <motion.div
                    whileHover={{
                      scale: 1.2,
                    }}
                  >
                    <div className="link-box">
                      <div className="link-box-icon">
                        <GroupsIcon sx={{ width: "60px", height: "90px" }} />
                      </div>
                      <div className="link-box-title">
                        <p>Roles</p>
                      </div>
                    </div>
                  </motion.div>
                </Link>
              )}
              {roles.includes("Admin") && (
                <Link
                  to="/updateinterviewer"
                  style={{ textDecoration: "none" }}
                >
                  <motion.div
                    whileHover={{
                      scale: 1.2,
                    }}
                  >
                    <div className="link-box">
                      <div className="link-box-icon">
                        <EngineeringIcon
                          sx={{ width: "60px", height: "90px" }}
                        />
                      </div>
                      <div className="link-box-title">
                        <p>Panelist Details Form</p>
                      </div>
                    </div>
                  </motion.div>
                </Link>
              )}

              {roles.includes("Admin") && (
                <Link to="/admin/forms" style={{ textDecoration: "none" }}>
                  <motion.div
                    whileHover={{
                      scale: 1.2,
                    }}
                  >
                    <div className="link-box">
                      <div className="link-box-icon">
                        <Search sx={{ width: "60px", height: "90px" }} />
                      </div>
                      <div className="link-box-title">
                        <p>Admin Search Forms</p>
                      </div>
                    </div>
                  </motion.div>
                </Link>
              )}
            </div>
          </div>
        )}
        {roles.includes("Interviewer") && (
          <div className="links-role-div">
            <h2 style={{ marginLeft: "50px" }}>Interviewer</h2>
            <div
              style={{
                borderRadius: "20px",
                display: "flex",
                backgroundColor: "#E5E4E2",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "10px",
                padding: "40px",
              }}
            >
              {roles.includes("Interviewer") && (
                <Link to="/candidatedetails" style={{ textDecoration: "none" }}>
                  <motion.div
                    whileHover={{
                      scale: 1.2,
                    }}
                  >
                    <div className="link-box">
                      <div className="link-box-icon">
                        <CreateIcon sx={{ width: "60px", height: "90px" }} />
                      </div>
                      <div className="link-box-title">
                        <p>Domain Interview Form</p>
                      </div>
                    </div>
                  </motion.div>
                </Link>
              )}
              {interviewType === "phone" && roles.includes("Interviewer") && (
                <Link
                  to="/candidatedetailsphone"
                  style={{ textDecoration: "none" }}
                >
                  <motion.div
                    whileHover={{
                      scale: 1.2,
                    }}
                  >
                    <div className="link-box">
                      <div className="link-box-icon">
                        <LocalPhoneIcon
                          sx={{ width: "60px", height: "90px" }}
                        />
                      </div>
                      <div className="link-box-title">
                        <p>Phone Interview Form</p>
                      </div>
                    </div>
                  </motion.div>
                </Link>
              )}
              {roles.includes("Interviewer") && (
                <Link to="/filledforms" style={{ textDecoration: "none" }}>
                  <motion.div
                    whileHover={{
                      scale: 1.2,
                    }}
                  >
                    <div className="link-box">
                      <div className="link-box-icon">
                        <Search sx={{ width: "60px", height: "90px" }} />
                      </div>
                      <div className="link-box-title">
                        <p>Search Domain Forms</p>
                      </div>
                    </div>
                  </motion.div>
                </Link>
              )}
            </div>
          </div>
        )}
        {roles.includes("CXO") && (
          <div className="links-role-div">
            <h2 style={{ marginLeft: "50px" }}>CXO</h2>
            <div
              style={{
                borderRadius: "20px",
                display: "flex",
                backgroundColor: "#E5E4E2",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "10px",
                padding: "40px",
              }}
            >
              {roles.includes("CXO") && (
                <Link to="/filledforms/cxo" style={{ textDecoration: "none" }}>
                  <motion.div
                    whileHover={{
                      scale: 1.2,
                    }}
                  >
                    <div className="link-box">
                      <div className="link-box-icon">
                        <Search sx={{ width: "60px", height: "90px" }} />
                      </div>
                      <div className="link-box-title">
                        <p>Search Forms</p>
                      </div>
                    </div>
                  </motion.div>
                </Link>
              )}
              {roles.includes("CXO") && (
                <Link to="/paneldump" style={{ textDecoration: "none" }}>
                  <motion.div
                    whileHover={{
                      scale: 1.2,
                    }}
                  >
                    <div className="link-box">
                      <div className="link-box-icon">
                        <Search sx={{ width: "60px", height: "90px" }} />
                      </div>
                      <div className="link-box-title">
                        <p>Panel Dump</p>
                      </div>
                    </div>
                  </motion.div>
                </Link>
              )}
              {roles.includes("CXO") && (
                <Link to="/reports" style={{ textDecoration: "none" }}>
                  <motion.div
                    whileHover={{
                      scale: 1.2,
                    }}
                  >
                    <div className="link-box">
                      <div className="link-box-icon">
                        <PieChartIcon sx={{ width: "60px", height: "90px" }} />
                      </div>
                      <div className="link-box-title">
                        <p>Reports</p>
                      </div>
                    </div>
                  </motion.div>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Home;
